import { Component, Vue } from 'vue-property-decorator';
import { Action, namespace } from 'vuex-class';
import { Logger } from 'fsts';
const authModule = namespace('auth');
const organizationModule = namespace('organization');
const logger = new Logger('silent-renew');

const folderModule = namespace('folder');
@Component({
  components: {},
})
export default class CallbackView extends Vue {
  @authModule.Action('signinSilentCallback')
  private signinSilentCallback!: any;
  @authModule.Action('getUser')
  private getUser!: any;
  @organizationModule.Action('getOrganizationsAll')
  async mounted() {
    try {
      logger.debug(`mounted`);
      const result = await this.signinSilentCallback();
      const account = await this.getUser();
    } catch (e) {
      logger.error(e);
    }
  }
}
